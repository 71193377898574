import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"
import { useDispatch } from "react-redux"
import styled, { keyframes, css } from "styled-components"
import { Box } from "../commonElements"
import {
  updateEntriesState,
  updateEntriesHistory,
} from "../../redux/entries/actions"
import CardElement from "./cardElement"
import numWords from "num-words"
import { rgba, lighten } from "polished"
import { usePersistValue } from "../../components/hook/usePersistValue"
import FlipCardElement, {
  BackImageBottom,
  FrontImageTop,
} from "../commonElements/flipCard"
import { useSpeechSynthesis } from "react-speech-kit"
import { sortNumbersArray, getRandomNumbers } from "../../utils/sorting"
import useEvent from "../hook/useEvent"
import { Howl } from "howler"
import FlipSound from "../../audio/card-flip.mp3"
import ShuffleSound from "../../audio/card-shuffle.mp3"

const cardWidth = "30rem"
const cardHeight = "35rem"
const mobileCardScale = 0.9

const FlipCardOuter = styled.div`
  cursor: pointer;
  position: relative;
  background-color: transparent;
  width: ${cardWidth};
  height: ${cardHeight};
  perspective: 1000px;
  ${props => props.pulseCard && PulseAnimate(props.toolColor, "2rem")}
  @media ${props => props.theme.device.mobileL} {
    width: calc(${cardWidth} * ${mobileCardScale});
    height: calc(${cardHeight} * ${mobileCardScale});
    ${props => props.pulseCard && PulseAnimate(props.toolColor, "1.4rem")}
  }
`

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform ${props => props.flipDuration};
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
  transform: ${props => `rotateY(${props.flipDeg}deg)`};
`

const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 1;
`

const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  z-index: 2;
`

const StackCardBox = styled(Box)`
  display: ${props => (props.shuffle ? "block" : "none")};
  position: absolute;
  top: 0;
  z-index: -1;
  transform: scale(0.8);
  ${props =>
    props.shuffle && ShuffleCardAnimate(props.top, props.left, props.right)}
  @media ${props => props.theme.device.mobileL} {
    ${props =>
      props.shuffle &&
      ShuffleCardAnimate(
        props.top * mobileCardScale,
        props.left * mobileCardScale,
        props.right * mobileCardScale
      )}
  }
`

const PulseAnimate = (toolColor, borderWidth) => css`
  animation: ${pulse(toolColor, borderWidth)} 1.5s;
  animation-iteration-count: 1;
`

const ShuffleCardAnimate = (top, left, right) => css`
  animation: ${shuffleCard(top, left, right)} 1.5s;
  animation-iteration-count: 1;
`

const pulse = (toolColor, borderWidth) => keyframes`
  0%{
    box-shadow: ${rgba(lighten(0.05, toolColor), 0.5)} 0 0 0 0;
    border-radius: 2rem;
  }
  75%{ 
    box-shadow: transparent 0 0 0 ${borderWidth};
    border-radius: 2rem;
  }
`

const shuffleCard = (top, left, right) => keyframes`
	0% {
    ${top && "top: 0rem"};
    ${left && "left: 0rem"};
    ${right && "right: 0rem"};
	}
	50% {
    ${top && `top: ${top}rem`};
    ${left && `left: ${left}rem`};
    ${right && `right: ${right}rem`};
   }
	100% {
    ${top && "top: 0rem"};
    ${left && "left: 0rem"};
    ${right && "right: 0rem"};
	}
`

const CardController = forwardRef(
  ({ cardRef, cardType, nextCardIndex, setNextCardIndex }, ref) => {
    const { value: toolColors } = usePersistValue(
      state => state.general.colors.array,
      ["#EEE", "#EEE"]
    )
    const { value: soundOn } = usePersistValue(
      state => state.general.sound.on,
      true
    )
    const { value: effectOn } = usePersistValue(
      state => state.general.effect.on,
      true
    )
    const { value: pronunciationOn } = usePersistValue(
      state => state.general.pronunciation.on,
      false
    )
    const { value: cardIndex } = usePersistValue(
      state => state.entries[cardType].index,
      -1
    )
    const { value: result } = usePersistValue(
      state => state.entries[cardType].result,
      []
    )
    const { value: list } = usePersistValue(
      state => state.entries[cardType].list,
      []
    )
    const { value: quantity } = usePersistValue(
      state => state.entries[cardType].quantity,
      0
    )
    const { value: sort } = usePersistValue(
      state => state.entries[cardType].sort,
      "R"
    )
    const { value: repeat } = usePersistValue(
      state => state.entries[cardType].repeat,
      false
    )
    const { value: history } = usePersistValue(
      state => state.entries.history,
      []
    )

    const [flipSound, setFlipSound] = useState(null)
    const [shuffleSound, setShuffleSound] = useState(null)
    const [isSwiping, setIsSwiping] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)
    const [pulseCard, setPulseCard] = useState("")
    const [shuffle, setShuffle] = useState(false)
    const [flipDeg, setFlipDeg] = useState("")
    const [flipDuration, setFlipDuration] = useState("0.6s")
    const [flipFold, setFlipFold] = useState("")
    const [flipFoldStyle, setFlipFoldStyle] = useState("")
    const [status, setStatus] = useState("front")
    const dispatch = useDispatch()

    const { speak, cancel, speaking } = useSpeechSynthesis()
    const [speechText, setSpeechText] = useState("")
    const sound = useRef(null)

    /* useEffect(() => {
      sound.current = new Howl({
        src: [FlipSound],
        onend: () => handleReadCard(speechText),
      })

      if (cardIndex !== -1 && !isAnimating) {
        if (pronunciationOn && speechText) {
          if (soundOn && flipSound) {
            handlePlaySound()
          } else {
            handleReadCard(speechText)
          }
        } else if (!pronunciationOn && soundOn && flipSound) {
          flipSound.play()
        }
      }

      return () => {
        if (sound.current) {
          sound.current.stop()
        }
      }
    }, [`${speechText};${cardIndex}`])

    const handlePlaySound = () => {
      if (sound.current) {
        sound.current.play()
      }
    }

    const handleReadCard = (speechText) => {
       //speak({ text: speechText }) 
    } */

    /* const divRef = useRef(null) */

    /* const triggerClick = () => {
      if (divRef.current) {
        divRef.current.click()
      }
    } */

    useEffect(() => {
      setFlipSound(
        new Howl({
          src: [FlipSound],
          volume: 0.5,
          html5: true,
        })
      )
      setShuffleSound(
        new Howl({
          src: [ShuffleSound],
          volume: 1,
        })
      )
    }, [])

    /* useEffect(() => {
      if (pronunciationOn && speechText && !speaking) {
        speak({ text: speechText })
      }
    }, [speechText]) */

    useEffect(() => {
      if (!pronunciationOn) {
        cancelPronounce()
      }
    }, [pronunciationOn])

    let flipFoldTimeout = null
    let flipFoldPronounceTimeout = null
    let flipFoldPulseTimeout = null
    let shuffleTrueTimeout = null
    let shuffleFalseTimeout = null

    useEffect(() => {
      if (cardIndex !== nextCardIndex) {
        cardRef.current[0].startFlipFold()
      }
    }, [nextCardIndex])

    useEffect(() => {
      resetFlipFoldTimeout()
      setFlipFold("reset")
      setFlipFold("")
      if (cardIndex !== -1) {
        if (effectOn) {
          setPulseCard(true)
        }
        if (pronunciationOn) {
          const resultCopy = sortNumbersArray(result, quantity, sort)
          handlePronounce(resultCopy[cardIndex]?.name)
        } else if (soundOn && flipSound) {
          flipSound.play()
        }
      }
    }, [cardIndex])

    const resetFlipFoldTimeout = () => {
      setIsAnimating(false)
      setIsSwiping(false)
      setPulseCard(false)
      if (soundOn && flipSound) {
        flipSound.stop()
      }
      cancelPronounce()
      clearTimeout(flipFoldPronounceTimeout)
      clearTimeout(flipFoldTimeout)
      clearTimeout(flipFoldPulseTimeout)
    }

    const resetShuffleTimeout = () => {
      setIsAnimating(true)
      setShuffle(false)
      if (soundOn && shuffleSound) {
        shuffleSound.stop()
      }
      clearTimeout(shuffleTrueTimeout)
      clearTimeout(shuffleFalseTimeout)
    }

    const cancelPronounce = () => {
      if (speaking) {
        cancel()
        setSpeechText("")
      }
    }

    const handlePronounce = numeral => {
      if (typeof numeral === "number") {
        if (numeral.toString().length > 9) {
          // Does not support converting more than 9 digits
        } else {
          const words = numWords(numeral)
          setSpeechText(words)
        }
      }
    }

    useImperativeHandle(ref, () => ({
      /* startFlip: () => {
        if (!shuffle && !isAnimating) {
          setFlipDuration("0.6s")
          setFlipDeg(status === "front" ? 180 : 0)
          setStatus(status === "front" ? "back" : "front")
        }
      }, */
      startShuffle: () => {
        if (!shuffle && !isAnimating) {
          resetShuffleTimeout()
          if (cardIndex !== -1 && status === "front") {
            setFlipDuration("0.6s")
            setFlipDeg(180)
            setStatus("back")
          }
          shuffleTrueTimeout = setTimeout(() => {
            setShuffle(true)
            if (soundOn && shuffleSound) {
              shuffleSound.play()
            }
            shuffleFalseTimeout = setTimeout(() => {
              setShuffle(false)
              setIsAnimating(false)
              setIsSwiping(false)
              dispatch(
                updateEntriesState(
                  cardType,
                  "result",
                  getRandomNumbers(list, quantity, repeat)
                )
              )
            }, 1500)
          }, 500)
        }
      },
      startFlipFold: () => {
        if (flipFold === "" && !shuffle && !isAnimating) {
          setIsSwiping(true)
          /* if (isSwiping) {
            // iphone might need user interaction to trigger sound
            triggerClick()
          } */
          if (nextCardIndex !== -1 && status === "back") {
            setFlipDuration("0s")
            setFlipDeg(0)
            setStatus("front")
          }
          setIsAnimating(true)
          const flipFoldType = nextCardIndex > cardIndex ? "flipUp" : "backFlip"
          setFlipFold(flipFoldType)
          setFlipFoldStyle(`
          ${BackImageBottom} {
            z-index: ${flipFoldType === "flipUp" ? 2 : 1};
          }
          ${FrontImageTop} {
            z-index: ${flipFoldType === "flipUp" ? 1 : 2};
          }
          `)

          resetFlipFoldTimeout()
          flipFoldTimeout = setTimeout(() => {
            setFlipFoldStyle(`
            ${BackImageBottom} {
              z-index: ${flipFoldType === "flipUp" ? 1 : 2};
            }
            ${FrontImageTop} {
              z-index: ${flipFoldType === "flipUp" ? 2 : 1};
            }
            `)
          }, 350)
        }
      },
      handleFlipped: direction => {
        if (!isSwiping) {
          if (
            (direction === "up" || direction === "down") &&
            flipFold === "" &&
            !shuffle &&
            !isAnimating &&
            list.length > 0
          ) {
            if (direction === "up" && cardIndex < quantity - 1) {
              setIsSwiping(true)
              setNextCardIndex(nextCardIndex + 1)
            } else if (direction === "down" && cardIndex > -1) {
              setIsSwiping(true)
              setNextCardIndex(nextCardIndex - 1)
            }
          }
        }
      },
    }))

    const handleKeyDown = event => {
      let keyCode = event.keyCode
      if (event.target.matches("input") || event.target.matches("textarea")) {
        return
      }
      if (
        keyCode === 37 ||
        keyCode === 40 ||
        keyCode === 39 ||
        keyCode === 38
      ) {
        event.preventDefault()
        if (keyCode === 37 || keyCode === 40) {
          // left & down
          cardRef.current[0].handleFlipped("down")
        } else {
          // right & up
          cardRef.current[0].handleFlipped("up")
        }
      }
    }
    useEvent("keydown", handleKeyDown)

    const FrontImage = () => {
      const frontCardIndex = flipFold === "flipUp" ? cardIndex + 1 : cardIndex
      return (
        <CardElement
          cardType={cardType}
          cardIndex={frontCardIndex}
          isBack={frontCardIndex === -1}
          toolColors={toolColors}
        />
      )
    }

    const BackImage = () => {
      const backCardIndex = flipFold === "flipUp" ? cardIndex : cardIndex - 1
      return (
        <CardElement
          cardType={cardType}
          cardIndex={backCardIndex}
          isBack={backCardIndex === -1}
          toolColors={toolColors}
        />
      )
    }

    const CardBehind = ({ top = null, left = null, right = null }) => {
      return (
        <StackCardBox shuffle={shuffle} top={top} left={left} right={right}>
          <CardElement
            cardType={cardType}
            cardIndex={-1}
            isBack={true}
            toolColors={toolColors}
          />
        </StackCardBox>
      )
    }

    return (
      <FlipCardOuter
        pulseCard={pulseCard && flipFold === ""}
        toolColor={toolColors[1]}
      >
        <FlipCardInner
          /* ref={divRef} */
          flipDeg={flipDeg}
          flipDuration={flipDuration}
          /* onClick={() => {
          if (cardIndex !== -1) {
            cardRef.current[0].startFlip()
          }
        }} */
        >
          <FlipCardFront>
            <FlipCardElement
              cardWidth={cardWidth}
              cardHeight={cardHeight}
              flipFold={flipFold}
              flipFoldStyle={flipFoldStyle}
              FrontImage={FrontImage}
              BackImage={BackImage}
              onAnimationEnd={() => {
                if (nextCardIndex !== -1 && nextCardIndex !== cardIndex) {
                  const resultCopy = [...result]
                  const sortedResult = sortNumbersArray(
                    resultCopy,
                    quantity,
                    sort
                  )
                  const historyCopy = [...history]
                  if (sortedResult[nextCardIndex].filtered === false) {
                    historyCopy.push(sortedResult[nextCardIndex].name)
                    dispatch(updateEntriesHistory(historyCopy))
                    const foundItem = resultCopy.find(
                      obj => obj.name === sortedResult[nextCardIndex].name
                    )
                    if (foundItem) {
                      foundItem.filtered = true
                      dispatch(
                        updateEntriesState(cardType, "result", resultCopy)
                      )
                    }
                  }
                }
                dispatch(updateEntriesState(cardType, "index", nextCardIndex))
              }}
            />
          </FlipCardFront>
          <FlipCardBack>
            <CardElement
              cardType={cardType}
              cardIndex={-1}
              isBack={true}
              toolColors={toolColors}
            />
          </FlipCardBack>
        </FlipCardInner>

        <CardBehind top="2" left="-28" />
        <CardBehind top="1" left="-29" />
        <CardBehind left="-30" />
        <CardBehind top="2" right="-28" />
        <CardBehind top="1" right="-29" />
        <CardBehind right="-30" />
      </FlipCardOuter>
    )
  }
)

export default CardController
