import React from "react"
import styled, { keyframes, css } from "styled-components"

const mobileCardScale = 0.9

const FlipContainer = styled.div`
  width: ${props => props.cardWidth};
  height: ${props => props.cardHeight};
  position: relative;
  perspective: 600px;
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc(${props => props.cardHeight} * ${mobileCardScale});
  }
`

const FlipTop = styled.div`
  overflow: hidden;
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2);
  ${props =>
    props.flipFold &&
    (props.flipFold === "reset"
      ? resetFlipFoldAnimation
      : flipFoldAnimation(props.flipFold, props.flipFoldStyle))}
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
  }
`

const FlipBottom = styled.div`
  position: relative;
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2);
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
  }
`

/* Front image */
export const FrontImageTop = styled.div`
  /* Top part of the front image */
  display: block;
  backface-visibility: hidden; /*Make the reverse side of the picture invisible*/
  position: absolute;
  top: 0px;
  width: ${props => props.cardWidth};
  height: calc(
    ${props => props.cardHeight} / 2 + 0.5px
  ); /*Half the height so that only the top part of the image is visible*/
  overflow: hidden; /*Hide the bottom part*/
  z-index: 2;
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc(
      (${props => props.cardHeight} / 2) * ${mobileCardScale} + 0.5px
    );
  }
`

const FrontImageBottom = styled.div`
  /* Bottom of front image */
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2);
  overflow: hidden;
  vertical-align: bottom;
  position: absolute;
  z-index: -2;
  & > div {
    position: absolute;
    top: calc(
      -${props => props.cardHeight} / 2
    ); /*Move the image up half the height to show the bottom*/
  }
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
    & > div {
      top: calc((-${props => props.cardHeight} / 2) * ${mobileCardScale});
    }
  }
`

/* Back image */
const BackImageTop = styled.div`
  height: calc(${props => props.cardHeight} / 2);
  position: absolute;
  top: 0px;
  vertical-align: top;
  overflow: hidden;
  z-index: -1;
  @media ${props => props.theme.device.mobileL} {
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
  }
`

export const BackImageBottom = styled.div`
  display: block;
  position: absolute;
  top: 0px;
  transform: rotateY(0deg) rotateZ(180deg) scaleX(-1); /*Turn the picture upside down and face back*/
  overflow: hidden;
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2 + 0.5px);
  backface-visibility: hidden;
  z-index: 1;
  & > div {
    position: absolute;
    top: calc(-${props => props.cardHeight} / 2);
  }
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc(
      (${props => props.cardHeight} / 2) * ${mobileCardScale} + 0.5px
    );
    & > div {
      top: calc((-${props => props.cardHeight} / 2) * ${mobileCardScale});
    }
  }
`

/*SHADOWS*/
const ShadowTopFront = styled.div`
  position: absolute;
  background: #000;
  z-index: 1000;
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2);
  backface-visibility: hidden;
  opacity: 0;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  ${props =>
    props.flipFold &&
    (props.flipFold === "reset"
      ? resetFlipFoldAnimation
      : ShadowTopFrontAnimate)}
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
  }
`

const ShadowTopBack = styled.div`
  position: absolute;
  top: 0px;
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2);
  background: #000;
  z-index: 1000;
  backface-visibility: hidden;
  transform: rotateY(
    180deg
  ); /*Turn the shadow around to face backwards so that it is not visible when flipped over*/
  opacity: 0; /*The shadow will disappear, so it is visible at first*/
  ${props =>
    props.flipFold &&
    (props.flipFold === "reset"
      ? resetFlipFoldAnimation
      : ShadowTopBackAnimate(props.flipFold))}
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
  }
`

const ShadowBottom = styled.div`
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2);
  position: absolute;
  z-index: -1;
  background: #000;
  opacity: 0;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  ${props =>
    props.flipFold &&
    (props.flipFold === "reset"
      ? resetFlipFoldAnimation
      : ShadowBottomAnimate(props.flipFold))}
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
  }
`

const shadowTopFront = keyframes`
	0% { opacity: 0; }
	70% { opacity: 0.1; }
	100% { opacity: 0; }
`

const shadowTopBack = flipFold => keyframes`
  0% { opacity: ${flipFold === "flipUp" ? 0 : 0.1}; }
	100% { opacity: ${flipFold === "flipUp" ? 0.1 : 0}; }
`

const shadowBottom = flipFold => keyframes`
	0% { opacity: ${flipFold === "flipUp" ? 0.1 : 0}; }
	100% { opacity: ${flipFold === "flipUp" ? 0 : 0.1}; }
`

const ShadowTopFrontAnimate = css`
  animation: ${shadowTopFront} 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 0, 1, 0.5);
  opacity: 0;
`

const ShadowTopBackAnimate = flipFold => css`
  animation: ${shadowTopBack(flipFold)} 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 0, 1, 0.5);
  opacity: 0;
`

const ShadowBottomAnimate = flipFold => css`
  animation: ${shadowBottom(flipFold)} 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 0, 1, 0.5);
  opacity: 0;
`

const flippingCard = flipFold => keyframes`
	0% {
		transform: rotate3d(1,0,0, ${flipFold === "flipUp" ? "-180deg" : "0deg"});
	}
	100% {
		transform: rotate3d(1,0,0, ${flipFold === "flipUp" ? "0deg" : "-180deg"});
	}
`

const flipFoldAnimation = (flipFold, flipFoldStyle) => css`
  width: ${props => props.cardWidth};
  height: calc(${props => props.cardHeight} / 2);
  transform-origin: bottom;
  animation: ${flippingCard(flipFold)} 0.5s; /*Set the name and duration of the animation*/
  animation-iteration-count: 1; /*Number of iterations for a given animation*/
  animation-timing-function: cubic-bezier(
    0,
    0,
    1,
    0.5
  ); /*Set a Bezier curve to calculate intermediate values of animated parameters*/
  transform: rotate3d(
    1,
    0,
    0,
    ${flipFold === "flipUp" ? "0deg" : "180deg"}
  ); /*Set the final rotation value, otherwise at the end of the animation the plane will return to 0 degrees*/
  ${flipFoldStyle}
  @media ${props => props.theme.device.mobileL} {
    width: calc(${props => props.cardWidth} * ${mobileCardScale});
    height: calc((${props => props.cardHeight} / 2) * ${mobileCardScale});
  }
`

const resetFlipFoldAnimation = css`
  ${FlipTop} {
    transform-origin: initial;
    animation: none;
  }

  ${ShadowTopFront},
  ${ShadowTopBack},
  ${ShadowBottom} {
    animation: none;
    opacity: 0;
  }
`

const FlipCard = ({
  cardWidth,
  cardHeight,
  flipFold,
  flipFoldStyle,
  FrontImage,
  BackImage,
  onAnimationEnd,
}) => {
  return (
    <FlipContainer
      cardWidth={cardWidth}
      cardHeight={cardHeight}
      onAnimationEnd={e => {
        e.stopPropagation()
        onAnimationEnd()
      }}
    >
      <FlipTop
        cardWidth={cardWidth}
        cardHeight={cardHeight}
        flipFold={flipFold}
        flipFoldStyle={flipFoldStyle}
      >
        <ShadowTopFront
          cardWidth={cardWidth}
          cardHeight={cardHeight}
          flipFold={flipFold}
        ></ShadowTopFront>
        <FrontImageTop cardWidth={cardWidth} cardHeight={cardHeight}>
          <FrontImage />
        </FrontImageTop>
        <BackImageBottom
          cardWidth={cardWidth}
          cardHeight={cardHeight}
          flipFold={flipFold}
        >
          <BackImage />
        </BackImageBottom>
        <ShadowTopBack
          cardWidth={cardWidth}
          cardHeight={cardHeight}
          flipFold={flipFold}
        ></ShadowTopBack>
      </FlipTop>
      <BackImageTop cardHeight={cardHeight}>
        <BackImage />
      </BackImageTop>
      <FlipBottom cardWidth={cardWidth} cardHeight={cardHeight}>
        <ShadowBottom
          cardWidth={cardWidth}
          cardHeight={cardHeight}
          flipFold={flipFold}
        ></ShadowBottom>
        <FrontImageBottom cardWidth={cardWidth} cardHeight={cardHeight}>
          <FrontImage />
        </FrontImageBottom>
      </FlipBottom>
    </FlipContainer>
  )
}

export default FlipCard
