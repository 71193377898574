import React from "react"
import { Box, Button, IconButton, Image } from "../commonElements"
import styled from "styled-components"
import { Shuffle } from "@styled-icons/fa-solid"
import ArrowBack from '../../images/common/ArrowBack.png'
import ArrowForward from '../../images/common/ArrowForward.png'
import { usePersistValue } from "../../components/hook/usePersistValue"

const IconTextButton = styled(Button)`
  font-size: 1.6rem;
  font-weight: 600;
  border-width: 0;
  width: 12rem;
  padding: 0;
  border-radius: ${props => props.borderRadius};
  cursor: ${props => props.disabled && "default"};
  opacity: ${props => props.disabled && "0.2"};
`

const IconTextBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;
`

const ButtonIcon = styled(Image)`
  width: 1.75rem;
  height: 1.35rem;
`

const VolumeIconButton = styled(IconButton)`
  width: 5.5rem;
  height: 5.5rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: ${props => props.disabled && "#BCBABA"};
  border: 3px solid ${props => props.disabled ? "#BCBABA" : "#3D3D3D"};
  cursor: ${props => props.disabled && "default"};
`

const FlipButtons = ({ cardType, shuffle, flip }) => {
    const { value: cardIndex } = usePersistValue(
        state => state.entries[cardType].index,
        -1
    )
    const { value: list } = usePersistValue(
        state => state.entries[cardType].list,
        []
    )
    const { value: quantity } = usePersistValue(
        state => state.entries[cardType].quantity,
        0
    )

    return (
        <Box
            flexDirection="row"
            height="5.5rem"
            padding="0 1rem"
        >
            <IconTextButton borderRadius="5px 0px 0px 5px"
                onClick={() => flip(cardIndex - 1)}
                disabled={list.length === 0 || cardIndex === -1}
            >
                <IconTextBox>
                    <ButtonIcon src={ArrowBack} alt="Back Flip" />
                    BACK FLIP
                </IconTextBox>
            </IconTextButton>
            <VolumeIconButton
                disabled={list.length === 0}
                icon={<Shuffle size={21} />}
                onClick={shuffle}
            />
            <IconTextButton borderRadius="0px 5px 5px 0px"
                onClick={() => flip(cardIndex + 1)}
                disabled={list.length === 0 || cardIndex === quantity - 1}
            >
                <IconTextBox>
                    FLIP-UP
                    <ButtonIcon src={ArrowForward} alt="Flip-Up" />
                </IconTextBox>
            </IconTextButton>
        </Box>
    )
}

export default FlipButtons
